import logo from '../../Utils/Assests/New.png'
import facebook from '../../Utils/Assests/FB.png'
import instagram from '../../Utils/Assests/INSTA.png'
import youtube from '../../Utils/Assests/YOU.png'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
const Footer = (props) => {
  const Navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.initialContent])

  return (
    <div>
      <div className="bg-[#000] ">
        <div className="flex flex-wrap justify-between max600:flex-col max600:justify-center p-12 gap-6 max1358:justify-center ">
          <div className="mb-5 pt-[8rem]">
            <a href="/" className="transition duration-200 flex justify-center">
              <img className="w-[15rem]" src={logo} alt="" /> {/* Logo  */}
            </a>
          </div>

          <ul className=" flex gap-20 max950:gap-16 text-[#fff] flex-wrap max1050:justify-center ">
            {' '}
            {/* Quick Links  */}
            <li className="flex flex-col gap-2 items-center text-center">
              <h2 className="">Usefull Links</h2>
              <div
                className="w-[100%] h-[0.2rem] text-white bar"
                style={{
                  background:
                    'linear-gradient(90deg, #FFD700 16.67%, #353534 90.74%)'
                }}
              ></div>
              <p
                className="cursor-pointer text-[#fff] text-decoration-none  mb-0"
                onClick={() => {
                  Navigate('/query')
                }}
              >
                Contact Us
              </p>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://bworkzlive.com/"
                target={'_blank'}
                rel="noreferrer"
              >
                BWORKZ
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="http://lissome.happyprancer.com"
                target={'_blank'}
                rel="noreferrer"
              >
                Lissome
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://happyprancer.com/"
                target={'_blank'}
                rel="noreferrer"
              >
                HappyPrancer
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://www.facebook.com/share/XBcvvs7ZENoqDReS/?mibextid=ox5AEW"
                target={'_blank'}
                rel="noreferrer"
              >
                Anupamz
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://www.acefitness.org/"
                target={'_blank'}
                rel="noreferrer"
              >
                ACE
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://s3-us-east-2.amazonaws.com/bworkzdocuments/brochure.pdf"
                target={'_blank'}
                rel="noreferrer"
              >
                Instructor Brochure
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://forms.gle/ahVeu2pSjJdWbADVA"
                target={'_blank'}
                rel="noreferrer"
              >
                Instructor Application
              </a>
              <a
                className="cursor-pointer text-[#fff] text-decoration-none"
                href="https://movatiathletic.com/"
                target={'_blank'}
                rel="noreferrer"
              >
                In Person BWORKZ classes
              </a>
            </li>
          </ul>
        </div>

        <div className=" h-16 bg-[#FDCF08] flex w-full items-center justify-start px-[1rem]">
          {' '}
          {/* Social media Links  */}
          <div className="flex  bg-[#000] justify-between  items-center w-[8rem]  rounded-2xl h-12 p-2">
            <a
              href="https://instagram.com/bworkz_workout?igshid=YmMyMTA2M2Y"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={instagram}
                alt=""
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8"
              />
            </a>
            <a
              href="https://www.facebook.com/BworkZLive?mibextid=LQQJ4dY"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={facebook}
                alt=""
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8"
              />
            </a>
            <a
              href="https://youtube.com/@bworkzworkout2004"
              target={'_blank'}
              rel="noreferrer"
            >
              <img
                src={youtube}
                alt=""
                className="hover:mr-2 hover:w-10 hover:h-10 w-8 h-8"
              />
            </a>
          </div>
        </div>

        <div className=" p-4 flex justify-center text-white gap-2 font-poppins max536:flex-col max536:text-center ">
          <div className="bg-[#FDCF08] w-1 border-[fff] rounded-md"></div>
          <h5 className="text-[1rem]">
            {' '}
            All rights reserved. © {new Date().getFullYear()} bworkzlive.com
          </h5>
        </div>
      </div>
    </div>
  )
}

export default Footer
