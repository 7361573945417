import React from 'react'
import anup from '../../Utils/Assests/Anup_1.png'
import './New.css'

const Header3 = () => {
  const services = [
    {
      title: 'Instructor Training',
      items: [
        'Online and In-person training BWORKZ is approved by ACE',
        'Learn how to conduct a Bollywood inspired class with 40 unique core basics.',
        'Master the technical nuances of key dance steps and learn how to impart this knowledge to your students.',
        'Learn how to provide a safe and effective BWORKZ class.',
        'Learn how to choose a playlist for your class.',
        'Learn how to open & close the session with key components like Warm up & Cooldown.',
        'Understand the importance of connecting with, inspiring, and motivating your students.'
      ]
    },
    {
      title: 'Jam Sessions',
      items: [
        'Regular free weekely online jam sessions',
        'Practice new choreography with fellow instructors.',
        'Get integrated with instructor community',
        'Get feedback from fellow instructors'
      ]
    },
    {
      title: 'Instructor License',
      items: [
        'License is valid as long as you stay as an active subscriber',
        'You are allowed to use any BWORKZ choreography in your in person Classes',
        'ACE certified license is accepted in most of the gymnasiums in USA and Canada'
      ]
    },
    {
      title: 'Other Benefits',
      items: [
        'Access to new choreographies',
        'Recorded classes.',
        'Free Profile Web Page',
        'Regular Choreography classes with Anupam and other senior instructors'
      ]
    }
  ]

  return (
    <div className="New flex justify-between max600:h-[85rem] h-[52rem]  blurimg w-[auto] relative pt-[3.5rem] pb-20 pr-5 pl-5 max600:flex-col max600:mx-0 max600:items-start max600:m-0 max600:w-[90vw] overflow-hidden max800:gap-[2rem]">
      <div className="p-10 flex flex-col max600:items-center justify-between bg-transparent border-y-[0.4rem] rounded-tl-lg rounded-bl-lg border-l-[0.4rem] border-[#FFD700] w-[40vw] h-[45rem] max600:h-auto max600:border-0 max600:w-[100%] max600:gap-12">
        {/* Render the first two services on the left side */}
        {services.slice(0, 2).map((service, index) => (
          <div
            className="w-[21rem] max800:w-[14rem] max600:w-[100%]"
            key={index}
          >
            <h1 className="text-[2rem] max800:text-[1.5rem] font-russo max600:text-[1.6rem]">
              {service.title}
            </h1>
            <ul className="max800:text-[0.8rem] list-disc">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="Over p-10 flex flex-col max600:items-center max600:pt-0 max950:gap-[10.8rem] gap-[12rem] items-end bg-transparent border-y-[0.4rem] rounded-tr-lg rounded-br-lg border-r-[0.4rem] border-[#FFD700] w-[38vw] h-[45rem] max600:h-auto max600:border-0 max600:w-[100%] max800:gap-[12rem]">
        {/* Render the last two services on the right side */}
        {services.slice(2).map((service, index) => (
          <div
            className="w-[20rem] max800:w-[14rem] max600:w-[100%]"
            key={index}
          >
            <h1 className="text-[2rem] max800:text-[1.5rem] max600:text-[1.6rem] font-russo max950:pl-[3rem] max600:pl-0">
              {service.title}
            </h1>
            <ul className="max800:text-[0.8rem] list-disc max950:pl-[3rem] max600:pl-0">
              {service.items.map((item, itemIndex) => (
                <li key={itemIndex}>{item}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <img
        src={anup}
        className="xs:block hidden absolute left-[51.9%] -translate-x-[60%] w-[32vw] max1078:-left-[50.9%]  borderbox-hidden bottom-[-39px] max1920:bottom-[-52px]"
        alt=""
      />
    </div>
  )
}

export default Header3
