import {CountrySelect, PhoneInput, PrimaryButton} from "../../Components/Auth/Inputs";

import "./Login.css";
const LoginForm = ({ handler }) => {
  return (
    
    <form
      onSubmit={handler}
      className={
        `flex flex-col items-center gap-6
        w-full`
      }
    >
      <h2 className='font-bold text-2xl'>Login</h2>
      <CountrySelect name='country' className='rounded w-full'/>
      <PhoneInput name='phone' className='rounded w-full'/>
      <PrimaryButton>Send OTP</PrimaryButton>
    </form>
   
  )
}

export default LoginForm;