const Choreography = () => {
  return (
    <div className="w-[100%] flex flex-col justify-center items-center pt-6">
      <iframe
        src="https://www.youtube.com/embed/videoseries?list=PLkl_0nrWH4iGcol0Y-itDyDRk0CffS1Q3"
        title="@#^&#{src-Components-Choreography-title}#$^#@"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        className="w-[76vw] h-[42vw] max1050:w-[86vw] max1050:h-[47vw]"
      ></iframe>
    </div>
  )
}

export default Choreography
