import React, { useState } from 'react'
import { useContext } from 'react'
import Context from '../../Context/Context'
import '../DashBoard/UsersList.css'
import { API } from 'aws-amplify'
import Pagination from '@mui/material/Pagination'
import 'bootstrap/dist/css/bootstrap.min.css'
import GoogleSearchIcon from '../../Utils/Assests/icons8-google-web-search-26.png'
import './UpcomingSessions.css'

const UsersListMobile = ({ userCheck, setUserCheck }) => {
  const Ctx = useContext(Context)
  const [isUserAdd, setIsUserAdd] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [status, setStatus] = useState('InActive')
  const [balance, setBalance] = useState('')
  const UtilCtx = useContext(Context).util
  const [userStatus, setUserStatus] = useState('all')
  // eslint-disable-next-line
  const [cognitoId, setCognitoId] = useState('')

  const filterUsersByStatus = (status) => {
    if (status === 'all') {
      return Ctx.userList
    }
    return Ctx.userList.filter((user) => user.status === status)
  }

  const availableStatuses = [
    'all',
    ...Array.from(new Set(Ctx.userList.map((user) => user.status)))
  ]

  console.log(availableStatuses)

  const itemsPerPage = 5 // Set the desired number of items per page
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(Ctx.userList.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const [searchQuery, setSearchQuery] = useState('') // Step 1: Add state for the search query

  const formatDate = (epochDate) => {
    const date = new Date(epochDate)
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0') // Month is zero-indexed, so we add 1 to get the correct month
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  // Step 2: Implement the search functionality
  const filter2 = filterUsersByStatus(userStatus)
  const filteredUserList = filter2
    .filter((user) => {
      return (
        user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.emailId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.phoneNumber.includes(searchQuery)
      )
    })
    .slice(startIndex, endIndex)
  // eslint-disable-next-line
  const onUpdateUser = async () => {
    if (!(name && email && phoneNumber && status && balance)) {
      alert('Fill all Fields')
      return
    }
    if (!name) {
      alert('Fill Name')
      return
    } else if (!email) {
      alert('Fill email')
      return
    } else if (!phoneNumber) {
      alert('Fill Phone Number')
      return
    } else if (!status) {
      alert('Fill Status')
      return
    } else if (!balance) {
      alert('Fill Balance')
      return
    }

    UtilCtx.setLoader(true)

    try {
      console.log('started')

      await API.put('user', '/admin/update-user/Bworkz', {
        body: {
          cognitoId: cognitoId,
          emailId: email,
          userName: name,
          phoneNumber: phoneNumber,
          status: status,
          balance: balance
        }
      })

      const newUserList = Ctx.userList.map((item) => {
        if (item.cognitoId === cognitoId) {
          item.status = status
          item.emailId = email
          item.userName = name
          item.phoneNumber = phoneNumber
          item.balance = balance
          return item
        } else {
          return item
        }
      })

      Ctx.setUserList(newUserList)

      alert('User Updated')

      setName('')
      setEmail('')
      setPhoneNumber('')
      setStatus('')
      setBalance('')

      // Ctx.onreload();

      UtilCtx.setLoader(false)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  const onCreateUser = async (e) => {
    e.preventDefault()

    if (!(name && email && phoneNumber && status && balance)) {
      alert('Fill all Fields')
      return
    }
    if (!name) {
      alert('Fill Name')
      return
    } else if (!email) {
      alert('Fill email')
      return
    } else if (!phoneNumber) {
      alert('Fill Phone Number')
      return
    } else if (!status) {
      alert('Fill Status')
      return
    } else if (!balance) {
      alert('Fill Balance')
      return
    }

    UtilCtx.setLoader(true)

    try {
      console.log('started')

      await API.post('user', '/admin/create-user/Bworkz', {
        body: {
          emailId: email,
          userName: name,
          phoneNumber: phoneNumber,
          status: status,
          balance: balance
        }
      })
      Ctx.setUserList([
        ...Ctx.userList,
        {
          emailId: email,
          userName: name,
          phoneNumber: phoneNumber,
          status: status,
          balance: balance
        }
      ])

      alert('User Added')

      setName('')
      setEmail('')
      setPhoneNumber('')
      setStatus('')
      setBalance('')

      UtilCtx.setLoader(false)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  const handleToggleUserAdd = () => {
    setUserCheck((prevState) => (prevState === 1 ? 0 : 1))
    setIsUserAdd((prevState) => !prevState)
  }

  const sendReminder = async (cognitoId) => {
    UtilCtx.setLoader(true)

    const pa = 'Bworkz@ybl'
    const pn = 'Bworkz'
    const am = 10

    try {
      const res = await API.post('user', `/user/send-email/Bworkz`, {
        body: {
          pa,
          pn,
          am,
          cognitoId
        }
      })

      console.log(res)

      alert(res.message)
      UtilCtx.setLoader(false)
    } catch (e) {
      console.log(e)
      UtilCtx.setLoader(false)
    }
  }

  return (
    <div className="w-full px-2 pb-4">
      <div className="container">
        {/* Step 1: Update the button class */}
        <button
          className={`filter-button bg-[#FDCF08] mt-0 text-[white] w-full m-[1rem] h-[2.1rem] rounded-lg text-snow ${
            isUserAdd && userCheck === 1 ? 'bg-[#FDCF08]' : '' // Add a style when the form is open
          }`}
          onClick={handleToggleUserAdd} // Use the custom function to handle toggle
        >
          {isUserAdd && userCheck === 1 ? 'Cancel' : 'Add New User'}{' '}
          {/* Toggle button text */}
        </button>
      </div>
      {isUserAdd && userCheck === 1 && (
        <form className="flex flex-col gap-6 w-full Sansita">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[0.5rem]">
            {/* Step 2: Apply the custom input field class to the input elements */}
            <input
              required
              placeholder="Name"
              className="input-field"
              type={'text'}
              value={name}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            <input
              required
              placeholder="Email Address"
              className="input-field"
              type={'email'}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
            <input
              required
              className="input-field"
              placeholder="Phone Number"
              type={'number'}
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
            <select
              className="input-field"
              onChange={(e) => {
                setStatus(e.target.value)
                console.log(e.target.value)
              }}
              value={status}
            >
              <option value={'Active'}>Active</option>
              <option value={'InActive'}>InActive</option>
            </select>
            <input
              required
              className="input-field"
              placeholder="Balance"
              type={'number'}
              value={balance}
              onChange={(e) => {
                setBalance(e.target.value)
              }}
            />
            <div className="flex gap-3 w-full justify-center items-center">
              {/* Step 4: Update the button styles */}
              <button
                className="sans-serif tracking-wider bg-[#FDCF08] text-[white] h-[2.4rem] w-[85%] rounded-lg py-2"
                onClick={onCreateUser}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="w-full flex justify-center pr-2 mb-3 mt-4">
        {/* Step 6: Add the search bar */}

        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by name, email, or phone"
            style={{
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.4)'
            }}
            className="p-1 rounded-[0.3rem] w-[80vw] ml-2"
          />

          <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
            {/* Custom Search Icon */}
            <img
              src={GoogleSearchIcon}
              alt="Search Icon"
              className="h-6 w-6 text-white opacity-80"
            />
          </span>
        </div>
      </div>
      <div className="flex">
        <div className="w-[95%] flex justify-end gap-3">
          <label className="font-bold" htmlFor="userStatusFilter">
            User Status:
          </label>
          <select
            className="rounded-[0.51rem] mr-3 py-1 px-1 bg-snow"
            id="userStatusFilter"
            value={userStatus}
            onChange={(e) => setUserStatus(e.target.value)}
            style={{ boxShadow: '0 0 12px rgba(0, 0, 0, 0.3)' }}
          >
            {availableStatuses.map((status) => (
              <option
                key={status}
                value={status}
                style={{ background: 'white' }}
              >
                {status === 'all' ? 'All' : status}
              </option>
            ))}
          </select>
        </div>
      </div>
      <h2 className="text-[1.4rem] mb-5 font-bold text-black-700 mt-10 text-center">
        Members List
      </h2>
      <div className="grid gap-[1.5rem] md:gap-4 grid-cols-1 sm:grid-cols-2">
        {filteredUserList.map((user, i) => (
          <div
            key={user.cognitoId}
            className="bg-gradient-to-r from-#1b7571  to-#1b7571 rounded-lg p-3 md:p-4 shadow-md"
            style={{
              background: `#eceaeaa1`,
              boxShadow: '0 0px 15px rgba(0, 0, 0, 0.4)',
              borderRadius: '1.5rem'
            }}
          >
            {isUserAdd && userCheck === 2 && cognitoId === user.cognitoId && (
              <form className="flex flex-col gap-[1rem] w-full Sansita">
                <input
                  required
                  placeholder="Name"
                  className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                  type={'text'}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
                <input
                  required
                  placeholder="Email Address"
                  className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                  type={'email'}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />

                <input
                  required
                  className="bg-[#eceaeaa1] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                  placeholder="Phone Number"
                  type={'number'}
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value)
                  }}
                />
                <div className="flex gap-3 w-full">
                  <select
                    className="bg-[#eceaeaa1] w-[42vw] text-[#00000064] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                    onChange={(e) => {
                      setStatus(e.target.value)
                    }}
                    value={status}
                  >
                    <option value={'Active'}>Active</option>
                    <option value={'InActive'}>InActive</option>
                  </select>
                  <input
                    required
                    className="bg-[#eceaeaa1] text-[#00000064] w-[42vw] p-[4px] pl-[1rem] rounded-[0.3rem] item-center"
                    placeholder="Balance"
                    type={'number'}
                    value={balance}
                    onChange={(e) => {
                      setBalance(e.target.value)
                    }}
                  />
                </div>
                <button
                  className="sans-sarif bg-[#FDCF08] text-white w-[87vw] tracking-wide rounded-[0.2rem] py-1"
                  onClick={(e) => {
                    e.preventDefault()
                    sendReminder(cognitoId)
                  }}
                >
                  Send Invoice
                </button>
                <div className="flex gap-3 w-full items-center justify-center">
                  <button
                    className="sans-sarif bg-[#FDCF08] text-[white] w-[41vw] tracking-wide rounded-[0.2rem] py-1 mb-4"
                    onClick={() => {
                      setIsUserAdd(false)
                      setUserCheck(0)
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="sans-sarif bg-[#FDCF08] text-[white] w-[41vw] tracking-wide rounded-[0.2rem] py-1 mb-4"
                    onClick={(e) => {
                      e.preventDefault()
                      onUpdateUser(user, status)
                    }}
                  >
                    Update
                  </button>
                </div>
              </form>
            )}

            <div className="flex items-center justify-between">
              <h3 className="text-lg font-bold mb-2">{user.userName}</h3>
            </div>
            <div className="mb-1">Email: {user.emailId}</div>
            <div>Phone: {user.phoneNumber}</div>
            <div className="mb-1">Country: {user.country}</div>
            <div className="mb-1">
              Joining Date: {formatDate(user.joiningDate)}
            </div>
            <div className="mb-1">Due:{user.balance}</div>
            <div className="mb-1">Status:{user.status}</div>
            <div className="mb-1">
              Attendance:{' '}
              {user.currentMonthZPoints ? user.currentMonthZPoints : 0}/
              {user.lastMonthZPoints ? user.lastMonthZPoints : 0}
            </div>
            <button
              onClick={() => {
                setIsUserAdd(true)
                setCognitoId(user.cognitoId)
                setName(user.userName)
                setEmail(user.emailId)
                setPhoneNumber(user.phoneNumber)
                setStatus(user.status)
                setBalance(user.balance)
                setUserCheck(2)
              }}
              className="bg-white rounded-[0.3rem] px-3 py-1 text-black font-bold text-center mt-2 w-[87vw]"
            >
              View Profile
            </button>
          </div>
        ))}
      </div>
      <div className="flex mb-[6rem] justify-center items-center mt-4 md:mt-6">
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
        />
      </div>
    </div>
  )
}

export default UsersListMobile
