import React, { useContext, useState } from 'react'
import Choreography from '../Components/DashBoard/Choreography'
import LeftBanner from '../Components/DashBoard/LeftBanner'
import PreviousSessions from '../Components/DashBoard/PreviousSessions'
import ProfileUpdate from '../Components/DashBoard/ProfileUpdate'
import UpcomingSessions from '../Components/DashBoard/UpcomingSessions'
import UsersList from '../Components/DashBoard/UsersList'
import NavBar from '../Components/NavBar'
import lock from '../Utils/Svg/lock.svg'
import { useNavigate } from 'react-router-dom'
import Context from '../Context/Context'

const DashBoard = () => {
  const Navigate = useNavigate()
  const [click, setClick] = useState(0)
  const [userCheck, setUserCheck] = useState(0)
  const Ctx = useContext(Context)
  const unpaidUser = {
    text: 'Embrace a world of possibilities. Unlock exclusive features. Subscribe now and elevate your experience with our extraordinary offerings.'
  }

  const displayAfterClick = () => {
    switch (click) {
      case 0:
        return <UpcomingSessions />

      case 1:
        return <PreviousSessions />

      case 2:
        return <Choreography />

      case 3:
        return <ProfileUpdate />

      case 4:
        return <UsersList userCheck={userCheck} setUserCheck={setUserCheck} />

      default:
        return <div></div>
    }
  }

  return (
    <div
      className="flex flex-col items-center w-screen min-h-screen bg-gradient-to-b from-[#cecece] via-[#d3d0b7] to-[#fdd0085b]"
      style={{ minHeight: '100vh' }}
    >
      <NavBar />
      <div className="w-[calc(100vw-1rem)] ml-4 rounded-3xl flex max1050:w-screen max1050:ml-0 max536:rounded-none max536:mt-10 items-center">
        <LeftBanner
          displayAfterClick={(data) => {
            setClick(data)
          }}
        />
        <div className="flex flex-col w-[100%] pt-8 max536:pt-0">
          {Ctx.userData.status === 'InActive' &&
          Ctx.userData.userType === 'member' ? (
            <div className="relative ">
              <div className="absolute z-10 top-[30%] right-[15%] max1050:w-[92%] flex justify-between rounded-[0.51rem]">
                <div className="w-[100%]">
                  <div className="locked-screen relative flex items-center justify-center text-center mt-[3.5rem]">
                    <div className="unlock-button">
                      <h2 className="w-[fit-content] text-[1.5rem] max500:text-[1.2rem] ml-[5rem]">
                        {unpaidUser.text}
                      </h2>
                      <div
                        className="Subscribe-button ml-[8rem] bg-[#FDCF08] w-[6rem] h-[3rem] m-[2rem] rounded-[1.5rem] text-white"
                        onClick={() => {
                          // Redirect the user to the subscription page
                          Navigate('/subscription')
                        }}
                      >
                        <img
                          src={lock} // Provide the source for the lock image
                          alt=""
                          className="top-8 w-[2.8rem] translate-x-[-2%] absolute fa-4x mb-4"
                          onClick={() => {
                            // Redirect the user to the subscription page
                            Navigate('/subscription')
                          }}
                        />

                        <span className="Latch">
                          <span className="Lock"></span>
                        </span>
                        <span className="UnlockText">UNLOCK</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-[20%]">{displayAfterClick()}</div>
            </div>
          ) : (
            <div>{displayAfterClick()} </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default DashBoard
